.App {
  text-align: center;
}

.app-wrapper {
  background-color: #ffffff;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.app-header {
  background-color: #037F89;
}

.name {
  text-align: end;
  padding: 1% 5%;
  color: white;
}

.app-container {
  padding: 2% 0%;
}

.app-container ul {
  display: flex;
  justify-content: center;
}

#pills-home, #pills-profile {
  padding: 2% 5%;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #037F89 !important;
  color: #fff !important;
}

.nav-link {
  color: #037F89 !important;
}

.responsive {
  display: block;
}

.options {
  display: flex;
  justify-content: space-between;
  margin: 0% 5%;
}

.select-page{
  width: 30%;
  margin: 0% 5%;
}